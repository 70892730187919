import cn from 'clsx';
import { disableUIBlocker } from 'main/components/common';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import PaymentStore from 'main/services/payments/PaymentStore';
import { isValidIban } from 'main/services/sepa/helpers/IbanValidationHelper';
import { SepaMandateDetails } from 'main/services/sepa/schemas/SepaMandateDetails';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SepaForm: FC = () => {
  const { t } = useTranslation();

  const [accountHolderNameValue, setAccountHolderNameValue] = useState('');
  const [accountHolderNameValid, setAccountHolderNameValid] = useState(false);
  const [ibanValue, setIbanValue] = useState('');
  const [ibanValid, setIbanValid] = useState(false);

  const ibanFieldRef = useRef<HTMLInputElement>(null);
  const accountHolderNameFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let iban = ibanValue?.trim();
    iban = iban.replace(/\s+/g, '').toUpperCase();
    if (iban?.length <= 0) {
      markIbanFieldInvalid(ibanFieldRef);
    } else {
      if (isValidIban(iban)) {
        markIbanFieldValid(ibanFieldRef);
      } else {
        markIbanFieldInvalid(ibanFieldRef);
      }
    }

    if (accountHolderNameValue?.trim()?.length <= 0) {
      markAccountholderFieldInvalid(accountHolderNameFieldRef);
    } else {
      markAccountholderFieldValid(accountHolderNameFieldRef);
    }

    function markIbanFieldValid(ibanRef: React.RefObject<HTMLInputElement>) {
      setIbanValid(true);
      markFieldValid(ibanRef);
    }

    function markAccountholderFieldInvalid(
      accountHolderNameRef: React.RefObject<HTMLInputElement>
    ) {
      setAccountHolderNameValid(false);
      markFieldInvalid(accountHolderNameRef);
    }

    function markAccountholderFieldValid(
      accountHolderNameFieldRef: React.RefObject<HTMLInputElement>
    ) {
      setAccountHolderNameValid(true);
      markFieldValid(accountHolderNameFieldRef);
    }

    function markIbanFieldInvalid(ibanRef: React.RefObject<HTMLInputElement>) {
      setIbanValid(false);
      markFieldInvalid(ibanRef);
    }
  }, [ibanValue, accountHolderNameValue]);

  function markFieldInvalid(fieldRef: React.RefObject<HTMLInputElement>) {
    fieldRef.current?.classList?.remove('border-transparent');
    fieldRef.current?.classList?.add('border-error');
  }

  function markFieldValid(fieldRef: React.RefObject<HTMLInputElement>) {
    fieldRef.current?.classList?.remove('border-error');
    fieldRef.current?.classList?.add('border-transparent');
  }

  const setMandateDetailsAndNavigateToConsent = async () => {
    disableUIBlocker();
    const sepaMandateDetails: SepaMandateDetails = {
      iban: ibanValue,
      customerName: accountHolderNameValue,
    };

    PaymentStore.sepaMandate = sepaMandateDetails;
    NavigationService.navigateTo(InternalPage.SepaMandateConsent);
  };

  return (
    <div>
      <input
        id="accountHolderName"
        placeholder={t('Account Holder Name')}
        type="text"
        value={accountHolderNameValue}
        onChange={(e) => setAccountHolderNameValue(e.target.value)}
        className="relative rounded-field bg-secondary h-14 p-sm focused-container w-full border-2 border-transparent"
        ref={accountHolderNameFieldRef}
      />

      <input
        id="iban"
        placeholder="IBAN"
        type="text"
        value={ibanValue}
        onChange={(e) => setIbanValue(e.target.value)}
        className="mt-2 relative rounded-field bg-secondary h-14 p-sm focused-container w-full border-2 border-transparent"
        ref={ibanFieldRef}
      />

      <div className={cn('flex mt-md')}>
        <PayButton
          onClick={() => setMandateDetailsAndNavigateToConsent()}
          title={
            PaymentStore.flow === PaymentIntentFlow.Manage
              ? t('Add new SEPA mandate')
              : t('Proceed to pay')
          }
          disabled={!ibanValid || !accountHolderNameValid}
        />
      </div>
    </div>
  );
};
