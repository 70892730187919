import PaymentStore from 'main/services/payments/PaymentStore';
import { FC } from 'react';
import { Trans } from 'react-i18next';

export const SepaMandateConsentDetails: FC = () => {
  switch (PaymentStore.legalEntity) {
    case 1:
      return <Trans i18nKey={'Sepa_LegalEntity_1_Consent'} />;
    case 70500:
      return <Trans i18nKey={'Sepa_LegalEntity_70500_Consent'} />;
    case 71000:
      return <Trans i18nKey={'Sepa_LegalEntity_71000_Consent'} />;
    case 12704:
      return <Trans i18nKey={'Sepa_LegalEntity_12704_Consent'} />;
    case 71500:
      return <Trans i18nKey={'Sepa_LegalEntity_71500_Consent'} />;
    case 50300:
      return <Trans i18nKey={'Sepa_LegalEntity_50300_Consent'} />;
    case 50800:
      return <Trans i18nKey={'Sepa_LegalEntity_50800_Consent'} />;
    case 71100:
      return <Trans i18nKey={'Sepa_LegalEntity_71100_Consent'} />;
    case 28500:
      return <Trans i18nKey={'Sepa_LegalEntity_28500_Consent'} />;
    case 40300:
      return <Trans i18nKey={'Sepa_LegalEntity_40300_Consent'} />;
    case 510001:
      return <Trans i18nKey={'Sepa_LegalEntity_510001_Consent'} />;
    case 930000:
      return <Trans i18nKey={'Sepa_LegalEntity_930000_Consent'} />;
    case 930008:
      return <Trans i18nKey={'Sepa_LegalEntity_930008_Consent'} />;
    case 930002:
      return <Trans i18nKey={'Sepa_LegalEntity_930002_Consent'} />;
    case 72200:
      return <Trans i18nKey={'Sepa_LegalEntity_72200_Consent'} />;
    case 910110:
      return <Trans i18nKey={'Sepa_LegalEntity_910110_Consent'} />;
    case 9009999:
      return <Trans i18nKey={'Sepa_LegalEntity_9009999_Consent'} />;
    case 73100:
      return <Trans i18nKey={'Sepa_LegalEntity_73100_Consent'} />;
    case 73400:
      return <Trans i18nKey={'Sepa_LegalEntity_73400_Consent'} />;
    case 75100:
      return <Trans i18nKey={'Sepa_LegalEntity_75100_Consent'} />;
    case 77100:
      return <Trans i18nKey={'Sepa_LegalEntity_77100_Consent'} />;
    case 441000:
      return <Trans i18nKey={'Sepa_LegalEntity_441000_Consent'} />;
    case 78200:
      return <Trans i18nKey={'Sepa_LegalEntity_78200_Consent'} />;
    case 71300:
      return <Trans i18nKey={'Sepa_LegalEntity_71300_Consent'} />;
    case 441001:
      return <Trans i18nKey={'Sepa_LegalEntity_441001_Consent'} />;
    case 71400:
      return <Trans i18nKey={'Sepa_LegalEntity_71400_Consent'} />;
    case 75300:
      return <Trans i18nKey={'Sepa_LegalEntity_75300_Consent'} />;
    case 71600:
      return <Trans i18nKey={'Sepa_LegalEntity_71600_Consent'} />;
    default:
      return null;
  }
};
